export default defineI18nConfig(() => ({
  legacy: false,
  locale: "de",
  fallbackLocale: "de",
  messages: {
    en: {
      welcome: "Welcome to",
      languages: {
        en: "English",
        de: "German",
      },
      zod: {
        nonempty: "This field must not be empty",
        email: "Please enter a valid e-mail address",
        length: "Must be {length} characters long",
        lengthMax: "Must be shorter than {length} characters",
        passwordSimple: "Please enter a password",
        passwordsDontMatch: "Passwords do not match",
        url: "This URL is incorrect or not formatted correctly.\nExample of a correct URL: www.aifinyochat.ai",
        hexColor: "Please enter a valid hex colour",
      },
      inputs: {
        delete: {
          no: "No",
        },
        FilterPopup: {
          all: "All",
          multiple: "Several",
        },
        clickHere: "Click here",
      },
      labels: {
        firstname: "First name",
        lastname: "Surname",
        email: "E-Mail",
        phone: "Telephone",
        street: "Street",
        streetNumber: "No.",
        zip: "Zip Code",
        city: "City",
        company: "Company",
        password: "Password",
        passwordConfirm: "Repeat password",
      },
      filter: {
        "last-2-days": "Last 2 days",
        "last-7-days": "Last 7 days",
        "last-4-weeks": "Last 4 weeks",
        "last-12-months": "Last 12 months",
      },
      userSettingsPages: {
        admin: {
          header: {
            title: {
              admin: "Admin data",
              user: "User data",
            },
            description: {
              admin: "Manage admin data",
              user: "Manage user data",
            },
          },
          upload: {
            p1: "Click on",
            p2: "Upload",
            p3: "or drag the SVG, PNG or JPG file (max. 1200x1200px) into this field.",
            error:
              "There was a problem with your last file. Please upload a new file.",
          },
          notification: {
            title: "E-mail notifications",
            description:
              "Receive email notifications about new conversations, weekly reports, etc.",
          },
          news: {
            title: "News & Updates",
            description:
              "Receive information on new products, promotions and much more conveniently in your mailbox.",
          },
        },
        bot: {
          header: {
            title: "Website integrations",
            description: "View all iFrames or integrations of your assistants",
          },
          copied: "Copy to the clipboard",
          toggle: "Open chat window automatically",
        },
        payment: {
          header: {
            title: "Plan",
            description: "Manage plan",
          },
          base: {
            title: "Your plan",
            endsAt: "Ends on",
            nextAt: "Next renewal on",
            noPlan: "No plan",
            changePlan: "Change plan",
            changePayment: "Change payment method",
          },
          additional: {
            title: "Additional packages",
            noPlan: "No additional package was selected.",
            addPlan: "Add contingent",
            usage: "of 250 have already been used up",
          },
          usage: {
            title: "Further information",
            period: "Current payment period:",
            messages: "Total news in the period:",
            messagesMax: "from maximum",
            user: "Users:",
            bots: "Bots:",
          },
          legal: {
            p1: "Further information can be found in our",
            p2: "AGB's",
            p3: "and in the",
            p4: "Data protection notice",
            p5: ".",
          },
        },
        user: {
          header: {
            title: "User administration",
            description: "Manage access",
          },
          invite: {
            title: "Add new users",
            options: {
              user: "Users",
              viewer: "Viewer",
            },
            description: {
              p1: "Admin",
              p2: "Can invite and delete users and assign access rights.",
              p3: "Users",
              p4: "Can create and edit bots.",
              p5: "Has no access to the user administration or plan.",
              p6: "Viewer",
              p7: "Can view bots, but not edit them.",
              p8: "Has no access to the user administration or plan.",
            },
            email: "E-mail address",
            invite: "Invite",
            userExistsAlready: "This user already exists",
          },
          list: {
            title: "Users",
            deleteTitle: "Delete user {email}",
            deleteText: "Yes, delete user",
            deleteButton: "Delete user",
          },
          invited: {
            title: "Invited users",
            deleteButton: "Delete user",
          },
        },
      },
      auth: {
        login: {
          title: "Log In",
          forgotPassword: "Forgotten your password?",
          noAccount: "Don't have an account yet?",
          error: {
            p1: "Incorrect e-mail address or password.",
            p2: "If you have just created your account, please try again in a few minutes.",
          },
          button: {
            submit: "Log in",
            retry: "Try again",
          },
        },
        request: {
          title: "Reset Password",
          hasAccount: "Already have an account?",
          button: {
            submit: "reset",
          },
          submitted: "Please check your e-mails.",
        },
        reset: {
          title: "Reset Password",
          hasAccount: "Already have an account?",
          button: {
            submit: "reset",
          },
          submitted: "Your password has been reset.",
          error: "Something has gone wrong, or the token is too old.",
          backToLogin: "Back to the login",
        },
        legal: {
          title: "The transmission is protected by SSL encryption.",
          name: "aifinyo AG",
          imprint: "Imprint",
          terms: "AGB",
          privacy: "Data protection notice",
        },
      },
      pages: {
        edit: {
          languages: {
            "ru-RU": "Russian (Russia)",
            "de-DE": "German (Germany)",
            "en-GB": "English (United Kingdom)",
            "en-US": "English (USA)",
            "fr-FR": "French (France)",
            "it-IT": "Italian (Italy)",
            "es-ES": "Spanish (Spain)",
            "uk-UA": "Ukrainian (Ukraine)",
            "pl-PL": "Polish (Poland)",
            "ro-RO": "Romanian (Romania)",
            "nl-NL": "Dutch (Netherlands)",
            "el-GR": "Greek (Greece)",
            "pt-PT": "Portuguese (Portugal)",
            "sv-SE": "Swedish (Sweden)",
            "hu-HU": "Hungarian (Hungary)",
            "cs-CZ": "Czech (Czech Republic)",
            "bg-BG": "Bulgarian (Bulgaria)",
            "da-DK": "Danish (Denmark)",
          },
          back: "back to overview",
          training: {
            outdated: {
              single: {
                updated: "updated",
                document: "Document",
              },
              multi: {
                updated: "updated",
                document: "Documents",
                "document-alt": "Documents",
              },
              first: "Start your first training session with",
              start: "Train new",
              startFirst: "Train chatbot",
            },
            training: {
              running: "Training...",
              single: {
                document: "Document",
              },
              multi: {
                document: "Documents",
                "document-alt": "Documents",
              },
              cancel: "Cancel training",
            },
            finished: {
              single: {
                document: "Document",
              },
              multi: {
                document: "Documents",
              },
              last: "Last training session",
              error: "Training status Incorrect",
              "last-error": "Last training Incorrect. Previous training",
            },
            stopped: {
              canceled: "Training cancelled",
              last: "Previous training",
              "last-canceled": "Last training session cancelled",
            },
            start: "Train new",
          },
          header: {
            preview: "Preview",
            publish: "Publish",
            isPublished: "Is published",
          },
          titles: {
            "1": "1. set assistant",
            "2": "2. connect data sources",
            "3": "3. configure chat window",
          },
          inputs: {
            name: "Name of the assistant (internal)",
            language: "Language",
            languagePopup:
              "The chatbot answers in the language of the question. If the chatbot does not recognise this language, it selects the set language.",
            url: "Your website",
            color: "Colour",
            widgetTitle: "Title (for customers)",
            widgetInto: "Introductory text",
            widgetLike: "Placeholder if a Like has been given",
            widgetDislike: "Placeholder if a dislike has been assigned",
            widgetFeedback: "Thank you for your feedback - Message",
          },
          sources: {
            wdb: "Knowledge Database",
            crawler: "Website Crawler",
            text: "Text",
            edit: "Edit",
            delete: "Delete",
            add: "Add another source",
          },
        },
        bots: {
          title: "Assistants",
          add: "Create Assistant",
          addTitle: "New assistant",
          table: {
            name: "Name",
            status: "Status",
            statusOptions: {
              active: "In operation",
              inactive: "In progress",
            },
            lastEdited: "Last edited",
            conversations: "Conversations",
            delete: {
              title:
                "Are you sure you want to permanently delete this assistant?",
              text: "Yes, delete assistant",
            },
            deactivate: {
              title: "Are you sure you want to deactivate this assistant?",
              info: "If the bot was integrated on your website, it will no longer be available there.",
              text: "Yes, deactivate",
            },
            button: {
              edit: "Edit",
              delete: "Delete",
              deactivate: "Deactivate",
            },
          },
        },
        conversations: {
          title: "Conversations",
          id: "Conversation",
          time: {
            sameDay: "[Today at] HH:mm [o'clock]",
            nextDay: "[Tomorrow at] HH:mm [o'clock]",
            nextWeek: "dddd [at] HH:mm [o'clock]",
            lastDay: "[Yesterday] HH:mm [o'clock]",
            lastWeek: "[Last] dddd [at] HH:mm [o'clock]",
            sameElse: "DD.MM.YYYY [at] HH:mm [o'clock]",
          },
          source: "Sources:",
          questions: "Receive questions",
          likes: "Likes",
          dislikes: "Dislikes",
          filter: "Period",
        },
        hub: {
          title: "AI Hub",
          tab: "AI Hub",
          messages: "News",
          show: "Show more",
        },
        wdb: {
          title: "Knowledge Database",
          tab: "Article",
          back: "back to the construction kit",
          createArticle: "Create article",
          filter: {
            all: "All",
            draft: "Draft",
            published: "Published",
            archived: "Archived",
            search: "Search article",
            author: "Authors",
            category: "Category",
            subCategory: "Subcategory",
          },
          table: {
            name: "Name",
            status: "Status",
            category: "Category",
            subCategory: "Subcategory",
            lastEdited: "Last update",
            statusOptions: {
              draft: "Draft",
              published: "Published",
              archived: "Archived",
            },
          },
          delete: {
            title: "Are you sure you want to delete the item '{name}'?",
            text: "Yes, delete article",
          },
          openArticle: {
            title: {
              create: "Create article",
              draft: "Create article",
              published: "Edit article",
              archived: "Edit article",
            },
            action: {
              create: "Save draft",
              draft: "Update draft",
              published: "Refresh",
              archived: "Archive",
              default: "Publish",
            },
            inputs: {
              title: "Title",
              category: "Category",
              subCategory: "Subcategory",
              author: "Author",
              images: "Pictures",
              content: "Text",
            },
          },
        },
        overview: {
          title: "Evaluation",
          filter: "Period",
          topics: {
            automation: "Degree of automation",
            topics: "Themes",
            likes: "Likes",
            dislikes: "Dislikes",
            conversations: "Conversations",
            messages: "Questions",
          },
        },
        products: {
          title: "Choose the right plan",
          monthly: "mtl.",
          item: {
            messages: "News",
            upTo: "until",
            bots: "Chat bots",
            users: "Users",
            sources: "Data sources",
            legal:
              "All prices exclude VAT. The data sources include a knowledge database and a powerful website crawler. You can create as many individual texts as you like.",
            base: {
              current: "Current plan",
              upgrade: "Select plan",
            },
            packages: {
              current: "Added",
              upgrade: "Add",
            },
          },
          legal: {
            p1: "Further information can be found in our",
            p2: "AGB's",
            p3: "and in the",
            p4: "Data protection notice",
            p5: ".",
          },
        },
      },
      stripe: {
        link: "Select plan",
        test: {
          title: "Days in the test phase",
          single: {
            remaining: "remaining",
          },
          multi: {
            remaining: "remaining",
          },
        },
        noTest: {
          title: "Your trial period has expired",
        },
        noSubscription: {
          title: "Your subscription has expired",
        },
        products: {
          types: {
            starter: {
              name: "Professional",
              description: "Ideal for up to 20 customer enquiries per day",
            },
            professional: {
              name: "Business",
              description: "Ideal for up to 50 customer enquiries per day",
            },
            enterprise: {
              name: "Premium",
              description: "Ideal for up to 100 customer enquiries per day",
            },
          },
          trial: {
            name: "Trial",
            description: "Trial subscription tier",
          },
          starter: {
            name: "Professional plan",
            description: "Ideal for up to 20 customer enquiries per day",
          },
          starter_extension_1: {
            name: "+ 250 messages",
            description:
              "You can flexibly book an additional quota of 500 messages.",
          },
          professional: {
            name: "Business plan",
            description: "Ideal for up to 50 customer enquiries per day",
          },
          professional_extension_1: {
            name: "+ 250 messages",
            description:
              "You can flexibly book an additional quota of 500 messages.",
          },
          enterprise: {
            name: "Premium plan",
            description: "Ideal for up to 100 customer enquiries per day",
          },
        },
      },
      header: {
        bots: "AI Assistant",
        workspace: "Construction Kit",
        conversations: "Conversations",
        overview: "Evaluation",
        addBot: "Add bot",
        botFilter: "Bots",
        botFilterTest: "Test",
        botFilterPublished: "Productive",
        settings: "Settings",
        logout: "Log out",
      },
    },
    de: {
      welcome: "Willkommen",
      languages: {
        en: "Englisch",
        de: "Deutsch",
      },
      zod: {
        nonempty: "Dieses Feld darf nicht leer sein",
        email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        length: "Muss {length} Zeichen lang sein",
        lengthMax: "Muss kürzer als {length} Zeichen sein",
        passwordSimple: "Bitte geben Sie  ein Passwort ein",
        passwordsDontMatch: "Passwörter stimmen nicht überein",
        url: "Diese URL ist inkorrekt oder nicht richtig formatiert. \nBeispiel einer korrekten URL: www.aifinyochat.ai",
        hexColor: "Bitte geben Sie eine gültige Hex-Farbe ein",
      },
      inputs: {
        delete: {
          no: "Nein",
        },
        FilterPopup: {
          all: "Alle",
          multiple: "Mehrere",
        },
        clickHere: "Hier klicken",
      },
      labels: {
        firstname: "Vorname",
        lastname: "Nachname",
        email: "E-Mail",
        phone: "Telefon",
        street: "Straße",
        streetNumber: "Nr.",
        zip: "PLZ",
        city: "Stadt",
        company: "Firma",
        password: "Passwort",
        passwordConfirm: "Passwort wiederholen",
      },
      filter: {
        "last-2-days": "Letzte 2 Tage",
        "last-7-days": "Letzte 7 Tage",
        "last-4-weeks": "Letzte 4 Wochen",
        "last-12-months": "Letzte 12 Monate",
      },
      userSettingsPages: {
        admin: {
          header: {
            title: {
              admin: "Admindaten",
              user: "Nutzerdaten",
            },
            description: {
              admin: "Admindaten verwalten",
              user: "Nutzerdaten verwalten",
            },
          },
          upload: {
            p1: "Klicken Sie auf",
            p2: "Upload",
            p3: "oder ziehen Sie die SVG-, PNG- oder JPG-Datei (max. 1200x1200px) in dieses Feld.",
            error:
              "Es gab ein Problem mit Ihrer letzten Datei. Bitte laden Sie eine neue Datei hoch.",
          },
          notification: {
            title: "E-Mail-Benachrichtigungen",
            description:
              "Erhalten Sie E-Mail-Benachrichtigungen zu neuen Konversationen, Wochenberichten usw.",
          },
          news: {
            title: "Neuigkeiten & Aktualisierungen",
            description:
              "Erhalten Sie Informationen zu Produktneuerungen, Aktionen und vielem mehr bequem in Ihr Postfach.",
          },
        },
        bot: {
          header: {
            title: "Website-Einbindungen",
            description:
              "alle iFrames bzw. Einbindungen Ihrer Assistenten einsehen",
          },
          copied: "In der Zwischenablage kopier",
          toggle: "Chatfenster automatisch öffnen",
        },
        payment: {
          header: {
            title: "Tarif",
            description: "Tarif verwalten",
          },
          base: {
            title: "Ihr Tarif",
            endsAt: "Endet am",
            nextAt: "Nächste Erneuerung am",
            noPlan: "Kein Tarif",
            changePlan: "Tarif ändern",
            changePayment: "Zahlungsmethode ändern",
          },
          additional: {
            title: "Zusatzpakete",
            noPlan: "Kein Zusatzpaket wurde ausgewählt.",
            addPlan: "Kontingent hinzubuchen",
            usage: "von 250 wurden bereits aufgebraucht",
          },
          usage: {
            title: "Weitere Informationen",
            period: "Aktuelle Zahlperiode:",
            messages: "Gesamtnachrichten im Zeitraum:",
            messagesMax: "von maximal",
            user: "Nutzer:",
            bots: "Bots:",
          },
          legal: {
            p1: "Weitere Informationen befinden sich in unseren",
            p2: "AGB's",
            p3: "und im",
            p4: "Datenschutzhinweis",
            p5: ".",
          },
          // usageAdditional: "von 250 wurden bereits aufgebraucht",
        },
        user: {
          header: {
            title: "Nutzerverwaltung",
            description: "Zugriffe verwalten",
          },
          invite: {
            title: "Neue Nutzer hinzufügen",
            options: {
              user: "Nutzer",
              viewer: "Betrachter",
            },
            description: {
              p1: "Admin",
              p2: "Kann Nutzer einladen, löschen und Zugriffsrechte vergeben.",
              p3: "Nutzer",
              p4: "Kann Bots erstellen und bearbeiten.",
              p5: "Hat kein Zugriff auf die Nutzerverwaltung oder Tarif.",
              p6: "Betrachter",
              p7: "Kann Bots einsehen, aber nicht bearbeiten.",
              p8: "Hat kein Zugriff auf die Nutzerverwaltung oder Tarif.",
            },
            email: "E-Mail-Adresse",
            invite: "Einladen",
            // input: {
            userExistsAlready: "Dieser Nutzer existiert bereits",
            // },
          },
          list: {
            title: "Nutzer",
            deleteTitle: "Nutzer {email} löschen",
            deleteText: "Ja, Nutzer löschen",
            deleteButton: "Nutzer löschen",
          },
          invited: {
            title: "Eingeladene Nutzer",
            deleteButton: "Nutzer löschen",
          },
        },
      },
      auth: {
        login: {
          title: "Anmelden",
          forgotPassword: "Passwort vergessen?",
          noAccount: "Sie haben noch keinen Account?",
          error: {
            p1: "Falsche E-Mail Adresse oder Passwort.",
            p2: "Falls Sie Ihren Account gerade erst erstellt haben, probieren Sie es bitte in ein paar Minuten erneut.",
          },
          button: {
            submit: "Anmelden",
            retry: "Erneut versuchen",
          },
        },
        request: {
          title: "Passwort zurücksetzen",
          hasAccount: "Sie haben schon ein Konto?",
          button: {
            submit: "zurücksetzen",
          },
          submitted: "Bitte überprüfen Sie Ihre E-Mails.",
        },
        reset: {
          title: "Passwort zurücksetzen",
          hasAccount: "Sie haben schon ein Konto?",
          button: {
            submit: "zurücksetzen",
          },
          submitted: "Ihr Passwort wurde zurückgesetzt.",
          error: "Etwas ist schief gelaufen, oder der Token ist zu alt.",
          backToLogin: "Zurück zum Login",
        },
        legal: {
          title:
            "Die Übertragung ist durch eine SSL-Verschlüsselung geschützt.",
          name: "aifinyo AG",
          imprint: "Impressum",
          terms: "AGB",
          privacy: "Datenschutzhinweis",
        },
      },
      pages: {
        edit: {
          languages: {
            "ru-RU": "Russisch (Russland)",
            "de-DE": "Deutsch (Deutschland)",
            "en-GB": "Englisch (Vereinigtes Königreich)",
            "en-US": "Englisch (USA)",
            "fr-FR": "Französisch (Frankreich)",
            "it-IT": "Italienisch (Italien)",
            "es-ES": "Spanisch (Spanien)",
            "uk-UA": "Ukrainisch (Ukraine)",
            "pl-PL": "Polnisch (Polen)",
            "ro-RO": "Rumänisch (Rumänien)",
            "nl-NL": "Niederländisch (Niederlande)",
            "el-GR": "Griechisch (Griechenland)",
            "pt-PT": "Portugiesisch (Portugal)",
            "sv-SE": "Schwedisch (Schweden)",
            "hu-HU": "Ungarisch (Ungarn)",
            "cs-CZ": "Tschechisch (Tschechische Republik)",
            "bg-BG": "Bulgarisch (Bulgarien)",
            "da-DK": "Dänisch (Dänemark)",
          },
          back: "zurück zur Übersicht",
          training: {
            outdated: {
              single: {
                updated: "aktualisiertem",
                document: "Dokument",
              },
              multi: {
                updated: "aktualisierte",
                document: "Dokumente",
                "document-alt": "Dokumenten",
              },
              first: "Starten Sie Ihr erstes Training mit",
              start: "Neu trainieren",
              startFirst: "Chatbot trainieren",
            },
            training: {
              running: "Training...",
              single: {
                document: "Dokument",
              },
              multi: {
                document: "Dokumente",
                "document-alt": "Dokumenten",
              },
              cancel: "training abbrechen",
            },
            finished: {
              single: {
                document: "Dokument",
              },
              multi: {
                document: "Dokumente",
              },
              last: "Letztes Training",
              error: "Trainings Status Fehlerhaft",
              "last-error": "Letztes Training Fehlerhaft. Vorheriges Training",
            },
            stopped: {
              canceled: "Training abgebrochen",
              last: "Vorheriges Training",
              "last-canceled": "Letztes Training abgebrochen",
            },
            start: "Neu trainieren",
          },
          header: {
            preview: "Vorschau",
            publish: "Veröffentlichen",
            isPublished: "Ist veröffentlicht",
          },
          titles: {
            1: "1. Assistent einstellen",
            2: "2. Datenquellen anbinden",
            3: "3. Chatfenster konfigurieren",
          },
          inputs: {
            name: "Name des Assistenten (intern)",
            language: "Sprache",
            languagePopup:
              "Der Chatbot antwortet in der Sprache der Frage. Sollte der Chatbot diese Sprache nicht erkennen, wählt er die eingestellte Sprache.",
            url: "Ihre Webseite",
            color: "Farbe",
            widgetTitle: "Titel (für Kunden)",
            widgetInto: "Einführungstext",
            widgetLike: "Platzhalter, wenn ein Like vergeben wurde",
            widgetDislike: "Platzhalter, wenn ein Dislike vergeben wurde",
            widgetFeedback: "Danke für dein Feedback - Nachricht",
          },
          sources: {
            wdb: "Wissensdatenbank",
            crawler: "Website Crawler",
            text: "Einzeltext",
            edit: "Bearbeiten",
            delete: "Löschen",
            add: "Weitere Quelle hinzufügen",
          },
        },
        bots: {
          title: "Assistenten",
          add: "Assistent anlegen",
          addTitle: "Neuer Assistent",
          table: {
            name: "Name",
            status: "Status",
            statusOptions: {
              active: "In Betrieb",
              inactive: "In Bearbeitung",
            },
            lastEdited: "Zuletzt bearbeitet",
            conversations: "Konversationen",
            delete: {
              title:
                "Sind Sie sicher, dass Sie diesen Assistenten unwiderruflich löschen möchten?",
              text: "Ja, Assistent löschen",
            },
            deactivate: {
              title:
                "Sind Sie sicher, dass Sie diesen Assistenten deaktivieren möchten?",
              info: "Falls der Bot auf Ihrer Website eingebunden wurde, wird er dort nicht mehr verfügbar sein.",
              text: "Ja, deaktivieren",
            },
            button: {
              edit: "Bearbeiten",
              delete: "Löschen",
              deactivate: "Deaktivieren",
            },
          },
        },
        conversations: {
          title: "Konversationen",
          id: "Konversation",
          time: {
            sameDay: "[Heute um] HH:mm [Uhr]",
            nextDay: "[Morgen um] HH:mm [Uhr]",
            nextWeek: "dddd [um] HH:mm [Uhr]",
            lastDay: "[Gestern] HH:mm [Uhr]",
            lastWeek: "[Letzten] dddd [um] HH:mm [Uhr]",
            sameElse: "DD.MM.YYYY [um] HH:mm [Uhr]",
          },
          source: "Quellen:",
          questions: "Fragen erhalten",
          likes: "Likes",
          dislikes: "Dislikes",
          filter: "Zeitraum",
        },
        hub: {
          title: "AI Hub",
          tab: "AI Hub",
          messages: "Nachrichten",
          show: "Nachrichten anzeigen",
        },
        wdb: {
          title: "Wissensdatenbank",
          tab: "Artikel",
          back: "zurück zum Baukasten",
          createArticle: "Artikel erstellen",
          filter: {
            all: "Alle",
            draft: "Entwurf",
            published: "Veröffentlicht",
            archived: "Archiviert",
            search: "Artikel suchen",
            author: "Authoren",
            category: "Kategorie",
            subCategory: "Unterkategorie",
          },
          table: {
            name: "Name",
            status: "Status",
            category: "Kategorie",
            subCategory: "Unterkategorie",
            lastEdited: "Letzte Aktualisierung",
            statusOptions: {
              draft: "Entwurf",
              published: "Veröffentlicht",
              archived: "Archiviert",
            },
          },
          delete: {
            title: `Sind Sie sicher das Sie den Artikel '{name}' löschen wollen?`,
            text: "Ja, Artikel löschen",
          },
          openArticle: {
            title: {
              create: "Artikel erstellen",
              draft: "Artikel anlegen",
              published: "Artikel bearbeiten",
              archived: "Artikel bearbeiten",
            },
            action: {
              create: "Entwurf speichern",
              draft: "Entwurf aktualisieren",
              published: "Aktualisieren",
              archived: "Archivieren",
              default: "Veröffentlichen",
            },
            inputs: {
              title: "Titel",
              category: "Kategorie",
              subCategory: "Unterkategorie",
              author: "Autor",
              images: "Bilder",
              content: "Text",
            },
          },
        },
        overview: {
          title: "Auswertung",
          filter: "Zeitraum",
          topics: {
            automation: "Automatisierungsgrad",
            topics: "Themen",
            likes: "Likes",
            dislikes: "Dislikes",
            conversations: "Konversationen",
            messages: "Fragen",
          },
        },
        products: {
          title: "Wählen Sie den passenden Tarif",
          monthly: "mtl.",
          item: {
            messages: "Nachrichten",
            upTo: "bis",
            bots: "Chat Bots",
            users: "Benutzer",
            sources: "Datenquellen",
            legal:
              "Alle Preise exkl. Mehrwertsteuer. Die Datenquellen umfassen eine Wissensdatenbank und einen leistungsstarken Website-Crawler. Sie können beliebig viele Einzeltexte erstellen.",
            base: {
              current: "Momentaner Tarif",
              upgrade: "Tarif wählen",
            },
            packages: {
              current: "Hinzugefügt",
              upgrade: "Hinzufügen",
            },
          },
          legal: {
            p1: "Weitere Informationen befinden sich in unseren",
            p2: "AGB's",
            p3: "und im",
            p4: "Datenschutzhinweis",
            p5: ".",
          },
        },
      },
      stripe: {
        link: "Tarif wählen",
        test: {
          title: "Tage in der Testphase",
          single: {
            remaining: "verbleibender",
          },
          multi: {
            remaining: "verbleibende",
          },
        },
        noTest: {
          title: "Deine Testphase ist abgelaufen",
        },
        noSubscription: {
          title: "Deine Subscription ist abgelaufen",
        },
        products: {
          types: {
            starter: {
              name: "Professional",
              description: "Ideal für bis zu 20 Kundenanfragen pro Tag",
            },
            professional: {
              name: "Business",
              description: "Ideal für bis zu 50 Kundenanfragen pro Tag",
            },
            enterprise: {
              name: "Premium",
              description: "Ideal für bis zu 100 Kundenanfragen pro Tag",
            },
          },
          trial: {
            name: "Trial",
            description: "Trial subscription tier",
          },
          starter: {
            name: "Professional Tarif",
            description: "Ideal für bis zu 20 Kundenanfragen pro Tag",
          },
          starter_extension_1: {
            name: "+ 250 Nachrichten",
            description:
              "Buchen Sie flexibel ein weiteres Kontingent von 500 Nachrichten hinzu.",
          },
          professional: {
            name: "Business Tarif",
            description: "Ideal für bis zu 50 Kundenanfragen pro Tag",
          },
          professional_extension_1: {
            name: "+ 250 Nachrichten",
            description:
              "Buchen Sie flexibel ein weiteres Kontingent von 500 Nachrichten hinzu.",
          },
          enterprise: {
            name: "Premium Tarif",
            description: "Ideal für bis zu 100 Kundenanfragen pro Tag",
          },
        },
      },
      header: {
        bots: "AI Assistant",
        workspace: "Baukasten",
        conversations: "Konversationen",
        overview: "Auswertung",
        addBot: "Bot Hinzufügen",
        botFilter: "Bots",
        botFilterTest: "Test",
        botFilterPublished: "Produktiv",
        settings: "Einstellungen",
        logout: "Abmelden",
      },
    },
  },
}));
